<template>
  <section class="avatar-img-wrapper relative" @mousedown="_clickModal">
    <img :src="avatarPic" alt="" />
    <i class="absolute pointer" @click="close" v-html="svg.close"></i>
    <button class="absolute" @click="$store.dispatch('delete_avatar')">
      ȘTERGE
    </button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { historyModal } from '../mixins/history-modal';
  export default {
    name: 'avatar-img-wrapper',
    mixins: [historyModal],
    computed: {
      ...mapState({
        pacient: 'pacient',
        svg: 'svg',
        avatarPic: 'avatarPic'
      })
    },
    methods: {
      close () {
        this.$store.commit('SET_MODAL', {
          name: undefined,
          val: false
        });
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .avatar-img-wrapper {
    img {
      border-radius: 4px;
      /*transform: scale(1.4);*/
    }
    i {
      top: -13px;
      right: -14px;
    }
    button {
      right: 0;
      bottom: -40px;
      background-color: @red;
      border: none;
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
</style>
